import { FC, useEffect, useState, useMemo, useCallback } from "react";
import { Box, useTheme } from "@mui/material";
import { FormikHelpers } from "formik";
import { Table } from "@vilocnv/allsetra-core";
import MilageRegExpendableRowCard from "components/cards/ReportCards/MilageRegExpendableRowCard/MilageRegExpendableRowCard";
import ReportsTopbar from "components/common/reports/ReportsTopbar/ReportsTopbar";
import ReportsFilterBar from "components/common/reports/ReportFiltersBar/ReportsFilterBar";

// Data
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector, useReportCommonStates } from "hooks";
import { MILAGE_REGISTRATION_TABLE_COLUMNS } from "app/data/constants";
import {
  commonReportsDataFormatter,
  commonReportsFilterFormValidationSchema,
  convertDatesForFilterPayload,
  convertToNormalTime,
  reportsMilageRegistrationFormInitialValues,
  reportTransformTimeForAPI,
  type FormattedData
} from "app/data/helpers";
import { generateReportThunk } from "app/features/reports/reportsActions";
import {
  getAllKeysByAccountThunk,
  resetReports,
  setReportsFilterData
} from "app/features";
import { selectAccountKeysState } from "app/data/selectors";
import ReportsCommonFilterForm from "components/forms/Reports/ReportsCommonFilterForm";
import { useMovementRideContext } from "contexts/MovementRideContext";

const DEFAULT_FILTER_VALUES = {
  driver: [],
  group: [],
  object: [],
  objectType: [],
  ridesModes: []
};

const MilageRegistration: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { showMovementRides } = useMovementRideContext();

  const {
    drawerSelectedAccountId,
    objectTypes,
    objectTypesLoading,
    objectsLoading,
    accountGroups,
    report,
    reportsLoading,
    filterOpen,
    setFilterOpen,
    dateFormat,
    setDateFormat,
    dateValues,
    setDateValues,
    handleObjectsSearch,
    selectedLocalObjects,
    handleOnChangeReportFilterForm
  } = useReportCommonStates();

  const [filterValues, setFilterValues] = useState<any>(DEFAULT_FILTER_VALUES);

  const [openModal, setOpenModal] = useState(false);

  const { accountKeys, loading: accountKeysLoading } = useAppSelector(
    selectAccountKeysState
  );

  const [apiPayload, setApiPayload] = useState(null);

  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  const tableColumns = useMemo(
    () => MILAGE_REGISTRATION_TABLE_COLUMNS(t),
    [t, showMovementRides]
  );

  const formattedData = useMemo(() => {
    let reportsData = [...report];
    reportsData = reportsData.map((reportItem) => ({
      ...reportItem,
      items: reportItem.items.filter(
        (item: { startedWithoutIginition: boolean }) =>
          showMovementRides ? true : !item.startedWithoutIginition
      )
    }));
    return commonReportsDataFormatter(reportsData, "name", dateFormat);
  }, [report, dateFormat, showMovementRides]);

  const milageRegistrationFiltersSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);

    const startDate = new Date(dateValues.startDate);
    const endDate = new Date(dateValues.endDate);

    setFilterValues(values);

    const { formattedStartDate, formattedEndDate } =
      convertDatesForFilterPayload(dateValues);

    const payload = {
      ...(apiPayload ?? {}),
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      ...values,
      reportType: 2,
      daysOfWeek:
        values.daysOfWeek.length === 7 ? [] : values.daysOfWeek.map(Number),
      startTime: reportTransformTimeForAPI(values.startTime + ":00", startDate),
      endTime: reportTransformTimeForAPI(values.endTime + ":00", endDate),
      rideModes: values?.rideModes?.map(Number) || [],
      startedWithoutIgnition: true
    };

    dispatch(setReportsFilterData(payload));
    setApiPayload(payload);

    formikHelpers.setSubmitting(false);
    setFilterOpen(false);
  };

  const handleDateChange = useCallback((value: any) => {
    setDateValues(value);
  }, []);

  const handleDateRangeSubmit = () => {
    const startDate = convertToNormalTime(dateValues.startDate);
    const endDate = convertToNormalTime(dateValues.endDate, true);

    let updatedFilterValues = { ...filterValues };

    if (filterValues.daysOfWeek) {
      updatedFilterValues = {
        ...filterValues,
        daysOfWeek:
          filterValues.daysOfWeek.length === 7
            ? []
            : filterValues.daysOfWeek.map(Number),
        startTime: reportTransformTimeForAPI(
          filterValues.startTime + ":00",
          dateValues.startDate
        ),
        endTime: reportTransformTimeForAPI(
          filterValues.endTime + ":00",
          dateValues.endDate
        )
      };
    }

    const payload = {
      ...(apiPayload ?? {}),
      startDate,
      endDate,
      ...updatedFilterValues,
      reportType: 2,
      rideModes: filterValues?.rideModes?.map(Number) || [],
      startedWithoutIgnition: true
    };

    setApiPayload(payload);
  };

  const handleLoadReport = () => {
    dispatch(setReportsFilterData(apiPayload));
    dispatch(generateReportThunk(apiPayload));
  };

  useEffect(() => {
    dispatch(resetReports());
    handleDateRangeSubmit();
  }, []);

  useEffect(() => {
    if (drawerSelectedAccountId !== null) {
      dispatch(getAllKeysByAccountThunk(drawerSelectedAccountId));
    }
  }, [drawerSelectedAccountId]);

  return (
    <Box>
      <ReportsTopbar
        dropdownTitle={t("reports.mileageRegistration")}
        disabled={reportsLoading ? true : false}
      />
      <Box mx={4}>
        <ReportsFilterBar
          setFilterOpen={setFilterOpen}
          dateFormat={dateFormat}
          setDateFormat={setDateFormat}
          handleDateChange={handleDateChange}
          dateValues={dateValues}
          handleDateRangeSubmit={handleDateRangeSubmit}
          dateRangeLoading={reportsLoading}
          disableExportButton={formattedData.length > 0 ? false : true}
          reportType={2}
          reportExportTypeValue={2}
          openModal={openModal}
          setOpenModal={setOpenModal}
          exportModalButton={true}
          hideExportButton={true}
          showRideModes={true}
          showToggleMovementRides={true}
          handleLoadReport={handleLoadReport}
        />
        <ReportsCommonFilterForm
          fetchOnDebounce={handleObjectsSearch}
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          onSubmit={milageRegistrationFiltersSubmitHandler}
          groups={accountGroups}
          objectTypes={objectTypes}
          objects={selectedLocalObjects}
          keys={accountKeys}
          dataLoading={
            objectsLoading || objectTypesLoading || accountKeysLoading
          }
          theme={theme}
          isMileageReport={true}
          //@ts-ignore
          onChange={handleOnChangeReportFilterForm}
          formInitialValues={reportsMilageRegistrationFormInitialValues}
          formValidations={commonReportsFilterFormValidationSchema}
          size="customSize"
        />
        <Table
          columns={tableColumns}
          data={formattedData}
          progressPending={reportsLoading}
          expandableRows
          expandOnRowClicked
          expandableRowsComponent={MilageRegExpendableRowCard}
          pagination={false}
          nestedRowPadding={"16px"}
          translator={t}
        />
      </Box>
    </Box>
  );
};

export default MilageRegistration;
